// company components
import DueDiligenceTabs from '../DueDiligenceTabs/DueDiligenceTabs';

// company containers
import CompanySideMenuContainer from '../../containers/CompanySideMenuContainer/CompanySideMenuContainer';

// company lib
import generateSubPages from '../../lib/generateSubPages.lib.company';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const DueDiligenceLayout = () => {
  const {company, subPage} = useSelector((state) => ({
    company: state.company.company,
    subPage: state.layout.subPage,
  }));

  return (
    <PageLayout
      sideContent={() => <CompanySideMenuContainer />}
      subPage={subPage}
      subPages={generateSubPages(company)}
      sections={[
        {
          section: '',
          component: <DueDiligenceTabs />,
        },
      ].filter((section) => !!section)}
    />
  );
};

export default DueDiligenceLayout;
