// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import setGeneralComplianceDataAction from './actions/setGeneralData.action.redux.compliance';

// style redux constants
import DEFAULT_DASHBOARD_REDUX_STATE from './constants/defaultState.constant.redux.compliance';

const complianceSlice = createSlice({
  name: 'compliance',
  initialState: DEFAULT_DASHBOARD_REDUX_STATE,
  reducers: {
    setGeneralComplianceData: setGeneralComplianceDataAction,
  },
});

export const {setGeneralComplianceData} = complianceSlice.actions;

export default complianceSlice.reducer;
