// ant components
import {Col, Row, Pagination} from 'antd';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useState} from 'react';

const PaginationList = ({
  children = () => null,
  list,
  paginationProps = {},
  perPage = 20,
  printView = false,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(perPage);

  const paginatedList = [...list].slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>{children(printView ? list : paginatedList)}</Col>
      {!printView && (
        <Col span={24}>
          <Pagination
            {...paginationProps}
            current={currentPage}
            onChange={(page) => setCurrentPage(page)}
            onShowSizeChange={(current, size) => {
              setCurrentPage(current);
              setPageSize(size);
            }}
            pageSize={pageSize}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            total={list.length}
          />
        </Col>
      )}
    </Row>
  );
};

PaginationList.propTypes = {
  children: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  paginationProps: PropTypes.object,
  perPage: PropTypes.number,
  printView: PropTypes.bool,
};

export default PaginationList;
