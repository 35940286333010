// ant components
import {Button} from 'antd';

// ant icons
import {PrinterOutlined} from '@ant-design/icons';

// company containers
import CompanySideMenuContainer from '../../../company/containers/CompanySideMenuContainer/CompanySideMenuContainer';

// company lib
import generateSubPages from '../../../company/lib/generateSubPages.lib.company';

// company routes
import suppliersDashboardRoute from '../../../company/pages/SuppliersDashboardPage/route';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// news components
import MeltwaterNewsAnalytics from '../../components/MeltwaterNewsAnalytics/MeltwaterNewsAnalytics';
import MeltwaterNewsCombinedLayout from '../../components/MeltwaterNewsCombinedLayout/MeltwaterNewsCombinedLayout';
import MeltwaterNewsList from '../../components/MeltwaterNewsList/MeltwaterNewsList';

// print components
import Print from '../../../print/components/Print/Print';
import PrintLayout from '../../../print/components/PrintLayout/PrintLayout';
import PrintSection from '../../../print/components/PrintSection/PrintSection';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const MeltwaterLayout = () => {
  const {company, subPage} = useSelector((state) => ({
    company: state.company.company,
    subPage: state.layout.subPage,
  }));

  return (
    <Print
      title="Articles"
      subtitle={company.CompanyName}
      trigger={({print}) => (
        <PageLayout
          previousPageLabel="Back to Dashboard"
          previousPageUrl={suppliersDashboardRoute(company.CompanyId)}
          sideContent={({goToSection}) => (
            <CompanySideMenuContainer goToSection={goToSection} />
          )}
          subPage={subPage}
          subPages={generateSubPages(company)}
          sectionName="News Articles"
          sections={[
            {
              section: 'News',
              component: <MeltwaterNewsCombinedLayout />,
              moreButton: (
                <Button icon={<PrinterOutlined />} onClick={print}>
                  Print Report
                </Button>
              ),
            },
          ].filter((section) => !!section)}
        />
      )}
    >
      <PrintLayout>
        <PrintSection title="Analytics">
          <MeltwaterNewsAnalytics />
        </PrintSection>
        <PrintSection title="Articles">
          <MeltwaterNewsList printView />
        </PrintSection>
      </PrintLayout>
    </Print>
  );
};

export default MeltwaterLayout;
