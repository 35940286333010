// company components
import DueDiligenceSection from '../DueDiligenceSection/DueDiligenceSection';

// compliance containers
// import FakeComplianceDueDiligenceContainer from '../../../compliance/containers/FakeComplianceDueDiligenceContainer/FakeComplianceDueDiligenceContainer';

// layout components
// import Tabs from '../../../layout/components/Tabs/Tabs';

// local components
import DDContainer from './components/DDContainer';

// react
import React from 'react';

const DueDiligenceTabs = () => {
  // const tabs = [
  //   {
  //     key: 'dueDiligence',
  //     label: 'Onboard Company',
  //     children: (
  //       <DDContainer>
  //         <DueDiligenceSection />
  //       </DDContainer>
  //     ),
  //   },
  //   {
  //     key: 'compliance',
  //     label: 'Compliance',
  //     children: <FakeComplianceDueDiligenceContainer />,
  //   },
  // ];

  // return <Tabs tabs={tabs} />;
  // return <FakeComplianceDueDiligenceContainer />;
  return (
    <DDContainer>
      <DueDiligenceSection />
    </DDContainer>
  );
};

export default DueDiligenceTabs;
