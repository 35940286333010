// ant components
import {Col as Column, Row, Table} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local components
import Image from './components/Image';
import Title from './components/Title';

// react
import React from 'react';

// react redux
import {useSelector} from 'react-redux';

// table components
import DynamicColumn from '../../../table/components/DynamicColumn/DynamicColumn';

// table lib
import generateGeneralColumn from '../../../table/lib/generateGeneralColumn.lib.table';

const CustomFinancialsTables = () => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  const columns = [
    generateGeneralColumn({
      category: 'entry',
      Component: DynamicColumn,
      title: '',
    }),
    generateGeneralColumn({
      category: 'value',
      Component: DynamicColumn,
      title: '',
    }),
  ];

  const marketCapColumns = [
    generateGeneralColumn({
      category: 'entry',
      Component: DynamicColumn,
      title: '',
    }),
    generateGeneralColumn({
      title: 'Current',
      category: 'Current',
      Component: DynamicColumn,
    }),
    generateGeneralColumn({
      title: '10/31/2024',
      category: '10/31/2024',
      Component: DynamicColumn,
    }),
    generateGeneralColumn({
      title: '3/31/2024',
      category: '3/31/2024',
      Component: DynamicColumn,
    }),
    generateGeneralColumn({
      title: '3/31/2023',
      category: '3/31/2023',
      Component: DynamicColumn,
    }),
    generateGeneralColumn({
      title: '3/31/2022',
      category: '3/31/2022',
      Component: DynamicColumn,
    }),
  ];

  return (
    <Row gutter={[20, 20]}>
      <Column span={24}>
        <Image src={company.customFinancials.image} />
      </Column>
      <Column span={24}>
        <StatementBlock title="Market Cap">
          <Table
            columns={marketCapColumns}
            dataSource={company.customFinancials.data.marketCap}
            pagination={false}
            rowKey="entry"
            size="small"
          />
        </StatementBlock>
      </Column>
      {Object.entries(company.customFinancials.data.tables).map(
        ([section, tables]) => (
          <Column span={24} key={section}>
            <Row gutter={[20, 20]}>
              <Column span={24}>
                <Title>{section}</Title>
              </Column>
              {Object.entries(tables).map(([title, data]) => {
                const parsedData = Object.entries(data).map(([key, value]) => ({
                  entry: key,
                  value,
                }));
                return (
                  <Column span={24 / 3} key={title}>
                    <StatementBlock title={title}>
                      <Table
                        columns={columns}
                        dataSource={parsedData}
                        pagination={false}
                        rowKey="entry"
                        size="small"
                        showHeader={false}
                      />
                    </StatementBlock>
                  </Column>
                );
              })}
            </Row>
          </Column>
        )
      )}
    </Row>
  );
};

export default CustomFinancialsTables;
